import Image from 'next/image'

// CSS
import styles from './index.module.css'

// React Bootstrap
import { Row, Col, Card, Container } from 'react-bootstrap'

// Constant Data
import Link from 'next/link'
import Swiper from 'react-id-swiper'
import { ratingJSX } from '../reviews/helper'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useRef } from 'react'

const wrapS3URLForUserTestimonials = (imageName: string) => {
  /**
   * Function to get the s3 image base url for testimonials
   */
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/${imageName}`
}

interface Swiper {
  swiper: any
}

interface Props {
  reviews: {
    tagline: string
    customerImgName: string
    alt: string
    comment: string
    customerName: string
    date: string
  }
}

const FoodiesLoveUs = (props): JSX.Element => {
  const { reviews } = props
  const ref = useRef<HTMLDivElement & Swiper>(null)
  const videoRef = useRef<HTMLDivElement & Swiper>(null)
  const goNext = () => {
    const swiperRef = ref.current
    if (swiperRef !== null && swiperRef.swiper !== null) {
      swiperRef.swiper.slideNext()
    }
  }

  const vGoNext = () => {
    const swiperRef = videoRef.current
    if (swiperRef !== null && swiperRef.swiper !== null) {
      swiperRef.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }

  const vGoPrev = () => {
    const swiperRef = videoRef.current
    if (swiperRef !== null && swiperRef.swiper !== null) {
      swiperRef.swiper.slidePrev()
    }
  }

  const params = {
    slidesPerView: 3,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.3,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2.3,
        spaceBetween: 20,
      },
      // when window width is >=1024px
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },

    loop: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  }
  return (
    <Container>
      <h2 className={styles.section__heading}>
        {props?.level === 'Bartender'
          ? 'People Love Us'
          : props?.level === 'Waiter'
          ? 'People Love Us'
          : props?.level === 'Cleaner'
          ? 'People Love Us'
          : 'Happy Customers'}
      </h2>
      <br />

      <div className={styles.OuterWrap}>
        <FaChevronLeft className={styles.PrevBtn} onClick={vGoPrev} />
        <div className={styles.Swiper}>
          <Swiper ref={videoRef} {...params} pagination={{ clickable: true }}>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/R4cePw92coU"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 2"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/QV30p0ic5iw"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 3"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/af8TPH2nY7Y"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 4"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/DxBLSOcC2nA"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 5"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/XIyL64tcwVw"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 6"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/Olm4dV3vMio"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 7"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/W8o6JK88uaY"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 8"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/_Cg85gkqpHo"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 9"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/4pTCwkpQVQo"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 10"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/LJSbzKscxb4"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="swiper-slide">
              <iframe
                title="Client satisfaction video 11"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/Yo2yRI9Sl2g"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Swiper>
        </div>
        <FaChevronRight className={styles.NextBtn} onClick={vGoNext} />
      </div>

      <br />
      <br />
      <br />

      <div className={styles.OuterWrap}>
        <FaChevronLeft className={styles.PrevBtn} onClick={goPrev} />
        <div className={styles.Swiper}>
          <Swiper ref={ref} {...params} pagination={{ clickable: true }}>
            {reviews?.map((review) => (
              <div className="swiper-slide" key={review.customerImgName}>
                <Card className="my-2" style={{ minHeight: '431px' }}>
                  <div className="mt-4 text-center">
                    {/* <Image
                  src={wrapS3URLForUserTestimonials(review.customerImgName)}
                  alt={review.alt}
                  width={100}
                  height={100}
                  objectFit="cover"
                  className={styles.customer__img}
                /> */}
                    <Image
                      src={wrapS3URLForUserTestimonials(review.customerImgName)}
                      width={100}
                      height={100}
                      alt="Customer ratings, feedbacks, reviews by satisfied customers who got amazing experience. Testimonials with guaranteed service delivery and assurance with COOX"
                      // objectFit="cover"
                      className={styles.customer__img}
                      // unoptimized={true}
                    />
                  </div>
                  <Card.Body>
                    <div className="text-center mb-1">{ratingJSX(5)}</div>
                    <h3 className={`text-center mb-3 ${styles.tagline}`}>
                      "{review.tagline}"
                    </h3>

                    <blockquote className="blockquote">
                      <p className={`text-muted mb-0 ${styles.comment}`}>
                        {review.comment}
                      </p>
                      <br />
                      <footer className="blockquote-footer">
                        {review.customerName}{' '}
                        <cite title={review.date}>{review.date}</cite>
                      </footer>
                    </blockquote>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Swiper>
        </div>
        <FaChevronRight className={styles.NextBtn} onClick={goNext} />
      </div>

      <br />
      <br />

      <Row>
        <Col className="text-center">
          <Link href="/reviews">
            <a className={`btn ${styles.learn__more__btn}`} target="_blank">
              Recent Reviews
            </a>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default FoodiesLoveUs
