import React from "react";

// REACT ICONS
import { FaStar } from 'react-icons/fa'


export const ratingJSX = (rating: number) => {
    return (

        <div>

            {rating > 4 ?
                <>
                    <div className="fa-x text-warning">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                    </div>
                </>
                : rating > 3 ?
                    <>
                        <span className="fa-x text-warning">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </span>
                        <span className="fa-x text-muted">
                            <FaStar />
                        </span>
                    </>
                    : rating > 2 ?
                        <>
                            <span className="fa-x text-warning">
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </span>
                            <span className="fa-x text-muted">
                                <FaStar />
                                <FaStar />
                            </span>
                        </>
                        : rating > 1 ?
                            <>
                                <span className="fa-x text-warning">
                                    <FaStar />
                                    <FaStar />
                                </span>
                                <span className="fa-x text-muted">
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </span>
                            </>
                            :
                            <>
                                <span className="fa-x text-warning">
                                    <FaStar />
                                </span>
                                <span className="fa-x text-muted">
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </span>
                            </>
            }
        </div>



    );
};